import React from 'react';
import About from './components/about/about.js';
import Merch from './components/merch/merch.js';
import Review from './components/reviews/reviews.js';
import Footer from './components/footer/footer.js';
import reviewData from './data/reviews.json';
import logo from './logo.png';
import './App.scss';

function App() {
  const reviews = reviewData.map((review, i) => {
      return <Review item={review} key={i} />
    } 
  );

  return (
    <div className="App">
      <header className="app-header">
        <img className="app-logo" src={logo} alt="logo" />
        <Merch />
      </header>
      <main>
        <About />
        <h1>- Reviews -</h1>
        <div className="review-wrapper">
          {reviews}
        </div>
      </main>
      <hr />
      <footer className="app-footer">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
