import React from 'react';
import ReactDOM from 'react-dom';

class Review extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0,
            isActive: false
        }

        this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount() {
		window.setTimeout(() => {
			const el = ReactDOM.findDOMNode(this);
            const height = el.querySelector('.panel-inner').scrollHeight;
			this.setState({
                height
			});
		}, 333);
    }
    
    toggleTab(event) {
        this.setState(prevState => ({
            isActive: !prevState.isActive
        }));
    }

    render() {
        const item = this.props.item;
        const { height, isActive } = this.state;
        const reviews = item.reviews.map((review, i) => <tr key={i}><td><a href={review.url} tabIndex={isActive ? 0 : -1}>{review.name}</a></td><td>{review.score}</td></tr> );
        const innerStyle = {
			height: `${isActive ? height : 0}px`
		}

        return (
            <div className="review">
                <img src={`/imgs/${item.image}`} className="review-img" alt={item.title} title={item.title} />
                <div className="panel" role="tabpanel" aria-expanded={isActive}>
                    <button className="panel-label" role="tab" onClick={this.toggleTab} onMouseDown={(e) => {e.preventDefault()}} >
                        <h2 className="review-title">{item.title}</h2>
                        <hr />
                    </button>
                    <div className="panel-inner" style={ innerStyle } aria-hidden={!isActive}>
                        <div className="panel-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Review</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reviews}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Review;