import React from 'react';

class About extends React.Component {
    
    render() {
        return (
            <div className="about">
                <h1 className="title">- About the band -</h1>
                <article>
                    <section className="container">
                        <p><b>Starblind</b> exists with the sole purpose of bringing some <b>Heavy fucking Metal</b> to the world! Heavy Metal that that pierces the heart and sounds the way it used to back in the 80´s: raw, melodic and in your face!</p>
                        <p>Some of the Starblind members are or have been involved with bands like <i>Danger</i>, <i>Sadauk</i>, <i>Palantír</i> and <i>Rocka Rollas</i> to name a few.</p>
                        <p><b>Starblind is:</b></p>
                    </section>
                    <div className="portrait-wrapper">
                        <figure>
                            <img src="/imgs/marcus_small.png" alt="Marcus S. Olkerud" title="Marcus S. Olkerud" />
                            <figcaption>Marcus S. Olkerud - Vocals</figcaption>
                        </figure>
                        <figure>
                        <img src="/imgs/johan_small.png" alt="Johan J.J. Jonasson" title="Johan 'J.J.' Jonasson" />
                            <figcaption>Johan 'J.J.' Jonasson - Guitar</figcaption>
                        </figure>
                        <figure>
                            <img src="/imgs/zak_small.png" alt="Zakarias Wikner" title="Zakarias Wikner" />
                            <figcaption>Zakarias Wikner - Drums</figcaption>
                        </figure>
                        <figure>
                            <img src="/imgs/bjorn_small.png" alt="Björn Rosenblad" title="Björn Rosenblad" />
                            <figcaption>Björn Rosenblad - Guitar</figcaption>
                        </figure>
                        <figure>
                            <img src="/imgs/daniel_small.png" alt="Daniel Tillberg" title="Daniel Tillberg" />
                            <figcaption>Daniel Yrell - Bass</figcaption>
                        </figure>
                    </div>
                </article>
            </div>
        );
    }
}

export default About;