import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

class Merch extends React.Component {
    constructor(props){
        super(props);
        library.add(fab, fas);
    }

    render() {
        return (
            <aside className="merch">
                <a href="https://starblindmerch.bigcartel.com/">Official merch <FontAwesomeIcon aria-hidden="false" role="button" icon={['fas', 'shopping-cart']} /></a>
            </aside>
        );
    }
}

export default Merch;