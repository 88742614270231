import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

class Footer extends React.Component {
    constructor(props){
        super(props);

        library.add(fab, fas);
    }

    render() {
        return (
            <div className="footer">
                <div className="footer-wrapper">
                    <div className="social-wrapper">
                        <h3>Social media</h3>
                        <a href="https://www.facebook.com/StarBlindtheBand/"><FontAwesomeIcon aria-hidden="false" role="button" icon={['fab', 'facebook']} /></a>
                        <a href="https://open.spotify.com/artist/1FwwgBtS9aSP0UQ18NjonE?si=NXbLAzZXQF2Jarw-FoCHCg"><FontAwesomeIcon aria-hidden="false" role="button" icon={['fab', 'spotify']} /></a>
                        <a href="https://www.instagram.com/starblind_official/"><FontAwesomeIcon aria-hidden="false" role="button" icon={['fab', 'instagram']} /></a>
                        <a href="https://twitter.com/StarBlindBand"><FontAwesomeIcon aria-hidden="false" role="button" icon={['fab', 'twitter']} /></a>
                        <a href="https://www.youtube.com/user/StarBlindOfficial"><FontAwesomeIcon aria-hidden="false" role="button" icon={['fab', 'youtube']} /></a>
                    </div>
                    <div className="contact-wrapper">
                        <h3>Contact us</h3>
                        <a href="mailto:info@starblind.se"><FontAwesomeIcon aria-hidden="false" role="button" icon={['fas', 'envelope']} /> info@starblind.se</a>
                    </div>
                </div>
                <p>copyright Starblind</p>
            </div>
        );
    }
}

export default Footer;